import { Box, Tooltip, Typography, useTheme } from '@mui/material';
import { StopAlaramIcon, CheckmarkCircleFilledLargeIcon } from 'src/assets';
import { StateLabelOnBox, StateLabelOffBox } from 'src/components';
import { StyledItemText, StyledTagItem } from './style';
import { TagType } from '../../types/types';
import { LabelTagBox } from '../label-tag-box';
import { convertSecondsToHours } from 'src/utilities';

type Props = {
  tag: TagType;
  isMobile: boolean;
  testId: string;
  liveDataComplete: any;
  isLiveDataWidget?: boolean;
};
export const TagItem = ({ tag, isMobile, testId, liveDataComplete, isLiveDataWidget }: Props) => {
  const liveDataTag = liveDataComplete?.tags?.find((item: TagType) => item.id === tag.tagId);

  const theme = useTheme();
  const isDataInSeconds = tag.rawUnit === 's';
  const greenColor = theme.palette.success.main;
  const redColor = theme.palette.error.main;
  const tagValue = Number(liveDataTag?.value || tag?.value);
  const displayName = liveDataTag?.displayName || tag?.displayName;
  const rawUnit = liveDataTag?.rawUnit || tag?.rawUnit;
  const displayedRawUnit = isDataInSeconds ? '(h)' : rawUnit && `(${rawUnit})`;
  //State tag
  const stateTag =
    (Array.isArray(liveDataTag?.connectedToAssets) &&
      liveDataTag?.connectedToAssets?.find(
        (item: any) => item.assignedTagLabel.toLowerCase() === 'state'
      )) ||
    (Array.isArray(tag?.connectedToAssets) &&
      tag?.connectedToAssets?.find((item) => item.assignedTagLabel.toLowerCase() === 'state'));
  //Alarm tag
  const alarmTag = liveDataTag?.dataType === 'alarm' || tag?.dataType === 'alarm';
  //Alarm priority tag
  const alarmPriorityTag = liveDataTag?.alarm || tag?.alarm;

  //State tag mapped to more assets
  const lengthOfConnectedAssetLiveTag =
    Array.isArray(liveDataTag?.connectedToAssets) && liveDataTag?.connectedToAssets?.length > 1;
  const lengthOfConnectedAssetTag =
    Array.isArray(tag?.connectedToAssets) && tag?.connectedToAssets?.length > 1;

  const isMoreAssets =
    (lengthOfConnectedAssetLiveTag &&
      liveDataTag?.connectedToAssets?.some((item: any) => item.assignedTagLabel !== 'state')) ||
    (lengthOfConnectedAssetTag &&
      tag?.connectedToAssets?.some((item) => item.assignedTagLabel !== 'state'));

  const isStateTagWithMoreDifferentAssets = stateTag && isMoreAssets;

  const numberOfAllowedChars = () => {
    if (isMobile) {
      if (isLiveDataWidget) {
        return window.screen.width > 500 ? 65 : 22;
      }
      return 18;
    }

    if (isLiveDataWidget) {
      return window.screen.width < 1000 ? 18 : 28;
    }

    return 35;
  };

  const displayNameAndRawUnit = `${displayName}${displayedRawUnit ? `${displayedRawUnit}` : ''}`;
  const displayNameLength = displayNameAndRawUnit?.length;

  const notRestOfTheTags = !stateTag && !alarmTag && !alarmPriorityTag;

  return (
    <StyledTagItem
      theme={theme}
      isMobile={isMobile}
      data-testid={testId}
      isLiveData={isLiveDataWidget}
    >
      <Tooltip
        title={displayNameAndRawUnit}
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: '#fff',
            },
          },
        }}
      >
        <StyledItemText isMobile={isMobile} isLiveData={isLiveDataWidget}>
          {displayNameAndRawUnit.slice(0, numberOfAllowedChars())}
          {displayNameLength > numberOfAllowedChars() && (
            <Typography component="span">... </Typography>
          )}
        </StyledItemText>
      </Tooltip>
      {!isStateTagWithMoreDifferentAssets && stateTag && tagValue === 1 ? (
        <StateLabelOnBox />
      ) : null}
      {!isStateTagWithMoreDifferentAssets && stateTag && tagValue === 0 ? (
        <StateLabelOffBox />
      ) : null}
      {!stateTag && alarmTag && tagValue === 0 ? (
        <Box>
          <CheckmarkCircleFilledLargeIcon fill={greenColor} width={16} height={16} />
        </Box>
      ) : null}
      {!stateTag && alarmTag && tagValue === 1 ? (
        <StopAlaramIcon width={16} height={16} fill={redColor} />
      ) : null}
      {!stateTag && !alarmTag && alarmPriorityTag && (
        <LabelTagBox alarmPriority={alarmPriorityTag} value={tagValue} />
      )}
      {notRestOfTheTags || isStateTagWithMoreDifferentAssets ? (
        <LabelTagBox
          alarmPriority="null"
          value={
            !isDataInSeconds ? tagValue : Number(convertSecondsToHours(tagValue, 2).slice(0, -1))
          }
        />
      ) : null}
    </StyledTagItem>
  );
};
