import FloatingAddLog from '../components/FloatingAddLog';
import LogForm from '../components/LogForm';
import { CustomWaterLoading, MenuPopoverItem, useTable } from 'src/components';
import { useAuthentication } from 'src/features/authentication/context';
import { useEffect, useRef, useState, useCallback } from 'react';
import { ApiState, FilterItem, LogItem } from '../types';
import { Container, Dialog, TablePagination } from '@mui/material';
import LogsList from '../components/LogsList';
import LogFilters from '../components/LogFilters';
import useQueryParamsActions from 'src/hooks/useQueryParamsActions';
import { useResponsive } from 'src/hooks';
import { LogsService } from 'src/services/logs';
import parseLogPayload from '../utils/parseLogPayload';
import { useSnackbar } from 'notistack';
import RestoreLogConfirmDialog from '../components/RestoreLogConfirmDialog';
import NoLogsPlaceholder from '../components/NoLogsPlaceholder';
import getAttachments from '../utils/getAttachments';
import { MobileAlarmPopUp } from 'src/features/alarm-pop-up/components/mobile/MobileAlarmPopUp';
import { DesktopAlarmPopUp } from 'src/features/alarm-pop-up/components/desktop/DesktopAlarmPopUp';
import { AlarmsService } from 'src/services';
import { AlarmRuleService } from 'src/services/alarmRules';
import { SitesService } from 'src/services/sites';
import { initializeAlarmData, initializeAlarmRule } from 'src/features/alarms/helper';
import { AlarmData } from 'src/features/alarms';

const Logs = () => {
  const {
    customerId: { value: customerId },
    siteId: { value: siteId },
  } = useAuthentication();
  const filtersRef = useRef<any>('');
  const [filters, setFilters] = useState('');
  const [logs, setLogs] = useState<{
    loaded: boolean;
    data: { totalCount: number; results: LogItem[] };
    fetched: boolean;
  }>({
    loaded: false,
    fetched: false,
    data: {
      results: [],
      totalCount: 0,
    },
  });
  const isMobile = useResponsive('down', 'md');
  const [alarm, setAlarm] = useState<any>({});
  const [isAlarmDataLoding, setIsAlarmDataLoading] = useState(false);
  const [alarmOpen, setAlarmOpen] = useState(false);
  const pagesRef = useRef<Record<number, string>>({});
  const rowsPerPageRef = useRef<number>(25);
  const [alarmRule, setAlarmRule] = useState<any>(null);
  const [notifications, setNotifications] = useState<any>(null);
  const { append, get, remove } = useQueryParamsActions();
  const [isCreatingLog, setIsCreatingLog] = useState(false);
  const [isDeletingLog, setIsDeletingLog] = useState(false);
  const [isRevertingLog, setIsRevertingLog] = useState(false);

  const [logToRevert, setLogToRevert] = useState<null | LogItem>(null);

  const [formsState, setFormsState] = useState<
    ApiState<
      {
        name: string;
        id: string;
        allowComments: boolean;
        isDefault: boolean;
      }[]
    >
  >({
    loading: true,

    data: [],
  });
  const formId = get('formId');
  const logId = get('logId');
  const version = get('version');
  const form = formsState.data.find((form) => form.id === formId);
  const fetchedFormsRef = useRef<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const onClose = useCallback(() => {
    remove('formId');
    remove('logId');
    remove('version');
  }, [remove]);

  const fetchLogs = useCallback(
    async (filters = '', lastLogId?: string, limit?: number) => {
      try {
        if (!customerId || !siteId) throw Error('Customer or site is not provided.');
        setLogs((prevState) => ({
          ...prevState,
          loaded: false,
          fetched: true,
        }));
        const logs = await LogsService.getAll(customerId!, siteId!, filters, lastLogId, limit);

        setLogs({
          data: {
            results: logs.results,
            totalCount: logs.total_count,
          },
          loaded: true,
          fetched: true,
        });
      } catch (err) {
        setLogs({ data: { results: [], totalCount: 0 }, loaded: true, fetched: true });
      }
    },
    [setLogs, customerId, siteId]
  );
  const { page, rowsPerPage, onChangeRowsPerPage, setPage } = useTable();

  const onFiltersChange = useCallback(
    async (filter: FilterItem[]) => {
      const filters: string[] = [];

      pagesRef.current = {};
      setPage(0);
      const eventTypeList: string[] = [];
      const alarmStatusList: string[] = [];
      const alarmPriorityList: string[] = [];
      const alarmAcknowledgement: string[] = [];

      filter.forEach((filt) => {
        if (filt.name === 'deleted_logs') {
          filters.push('includeDeletedLogs=true');
        }
        if (filt.name === 'time_frame') {
          filters.push('startDate=' + (filt!.value as string[])[0]);
          filters.push('endDate=' + (filt!.value as string[])[1]);
        }
        if (filt.group.name === 'alarm_type') {
          alarmPriorityList.push(filt.name);
        }
        if (filt.group.name === 'alarm_acknowledgement') {
          alarmAcknowledgement.push(filt.name);
        }
        if (filt.group.name === 'event_type' && filt.value === true) {
          eventTypeList.push(filt.name);
        }
        if (filt.group.name === 'alarm_status' && filt.value === true) {
          alarmStatusList.push(filt.name);
        }

        if (filt.name === 'assets' && Array.isArray(filt.value)) {
          filt.value.forEach((et: any) => filters.push('assetIdList=' + et.id));
        }
      });

      if (eventTypeList.length) {
        eventTypeList.forEach((et) => filters.push(`eventTypeList=${et}`));
      }
      if (alarmAcknowledgement.length === 1) {
        if (alarmAcknowledgement.includes('acknowledged')) {
          alarmAcknowledgement.forEach((et) => filters.push(`isAcknowledged=true`));
        } else {
          alarmAcknowledgement.forEach((et) => filters.push(`isAcknowledged=false`));
        }
      }

      if (alarmPriorityList.length) {
        alarmPriorityList.forEach((et) => filters.push(`alarmPriorityList=${et}`));
      }
      if (alarmStatusList.length) {
        alarmStatusList.forEach((et) => filters.push(`alarmStatusList=${et}`));
      }

      setFilters(filters.join('&'));
    },
    [setPage]
  );

  // Log operations
  const handleCreateLog = async (data: any, logFormId?: string, allowComments?: boolean) => {
    if (!form?.id && !logFormId) return;

    const log: any = parseLogPayload(
      data,
      (form?.id || logFormId) as string,
      !!allowComments,
      logId
    );

    const attachments = getAttachments(data);

    if (!customerId || !siteId) return;

    const maxSizeInMB = 10; // 10 MB
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024; // Convert MB to Bytes

    let hasTooLongFile = false;
    Object.values(attachments).forEach((attachment: any) => {
      if (Array.isArray(attachment)) {
        attachment.forEach((file: any) => {
          if (file?.size > maxSizeInBytes) {
            hasTooLongFile = true;
          }
        });
      }
    });

    if (hasTooLongFile)
      return enqueueSnackbar('Uploaded file size is too long! (Maximum is: 10mb)', {
        variant: 'error',
      });

    try {
      setIsCreatingLog(true);
      await LogsService.add(customerId, siteId, log, attachments, logId ?? undefined);
      await fetchLogs(filtersRef.current);
      enqueueSnackbar('Log successfully created!', { variant: 'success' });
      setIsCreatingLog(false);
      onClose();
    } catch (err) {
      enqueueSnackbar('Log creation failed!', { variant: 'error' });
      setIsCreatingLog(false);
    }
  };
  const handleDeleteLog = async (logId: string) => {
    try {
      setIsDeletingLog(true);
      await LogsService.patch(customerId!, siteId!, logId, { status: 'inactive' });

      enqueueSnackbar('Log successfully deleted!', { variant: 'success' });
      setIsDeletingLog(false);
      onClose();
      await fetchLogs(filtersRef.current);
    } catch (err) {
      enqueueSnackbar('Log deletion failed! Message', { variant: 'error' });
      setIsDeletingLog(false);
    }
  };
  const handleRevertLog = async (logId: string) => {
    try {
      setIsRevertingLog(true);
      await LogsService.patch(customerId!, siteId!, logId, { status: 'active' });

      enqueueSnackbar('Log successfully reverted!', { variant: 'success' });
      setIsRevertingLog(false);
      setLogToRevert(null);
      await fetchLogs(filtersRef.current);
    } catch (err) {
      enqueueSnackbar('Log revert failed! Message', { variant: 'error' });
      setIsRevertingLog(false);
      setLogToRevert(null);
    }
  };
  const handleSelectAlarm = useCallback(
    (eventId: string, siteId: string) => {
      if (!customerId) return;

      if (siteId !== '' && eventId !== '') {
        setIsAlarmDataLoading(true);
        AlarmsService.getAlarmPopupInfo(customerId, siteId, eventId)
          .then((response: AlarmData) => {
            if (!customerId) return;
            setAlarm(response);
            AlarmRuleService.getById(customerId, siteId, response.ruleId)
              .then((response) => {
                setAlarmOpen(true);
                setAlarmRule(response);
                setIsAlarmDataLoading(false);
              })
              .catch((error) => {
                setAlarmRule(initializeAlarmRule());
                setAlarmOpen(true);
                setIsAlarmDataLoading(false);
                console.error(error);
              });
          })
          .catch((error) => {
            setAlarm(initializeAlarmData());
            setIsAlarmDataLoading(false);
            console.error(error);
          });
      }

      AlarmsService.getAlarmPopupNotifications(customerId, eventId)
        .then((response: Notification[]) => {
          setNotifications(response);
        })
        .catch((error) => {
          setNotifications([]);
          console.error(error);
        });
    },
    [customerId, setAlarm, setAlarmRule, setNotifications]
  );

  useEffect(() => {
    filtersRef.current = filters;

    fetchLogs(filters, undefined, rowsPerPageRef.current);
  }, [fetchLogs, filters]);

  useEffect(() => {
    if (!fetchedFormsRef.current) {
      (async () => {
        const data = await SitesService.getAssignedForms(customerId!, siteId!);
        setFormsState({ loading: false, data });
        fetchedFormsRef.current = true;
      })();
    }
  }, [customerId, siteId]);

  const pageRef = useRef(page);

  useEffect(() => {
    pageRef.current = page;
  }, [page]);

  const logsRef = useRef<any>([]);

  useEffect(() => {
    logsRef.current = logs.data.results;
  }, [logs]);

  const formsNotAvailable = !formsState.loading && (!formsState.data || !formsState.data.length);

  return (
    <Container
      maxWidth={false}
      sx={{
        paddingInline: '0px !important',
        display: 'flex',
        height: isMobile ? '100%' : 'calc(100% - 138px)',
        flexDirection: 'column',
      }}
    >
      <LogFilters onChange={onFiltersChange} />

      <TablePagination
        component={'div'}
        sx={{
          borderTop: 0,
          height: '42px',
          overflow: 'hidden',
          '.MuiTablePagination-selectLabel': { mt: 0, mb: 0 },
          '.MuiTablePagination-displayedRows': { mt: 0, mb: 0 },
        }}
        count={logs.data.totalCount}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={(event: unknown, nextPage) => {
          let skipTo = '';

          const isBack = nextPage < pageRef.current;
          if (nextPage && !isBack) {
            pagesRef.current[nextPage] = logsRef.current[logsRef.current.length - 1]?.id;
          }
          if (isBack) {
            skipTo = pagesRef.current[nextPage];
          } else {
            skipTo = logsRef.current[logsRef.current.length - 1].id;
          }

          setPage(nextPage);
          fetchLogs(filtersRef.current, skipTo, rowsPerPageRef.current);
        }}
        onRowsPerPageChange={(event) => {
          setPage(0);
          pagesRef.current = {};
          rowsPerPageRef.current = parseInt(event.target.value) as number;
          fetchLogs(filtersRef.current, undefined, parseInt(event.target.value) as number);
          onChangeRowsPerPage(event as any);
        }}
      />
      <Dialog open={isAlarmDataLoding}>
        <CustomWaterLoading />
      </Dialog>

      {isMobile ? (
        <MobileAlarmPopUp
          open={alarmOpen}
          onClose={() => {
            setAlarmOpen(false);
            setAlarm({});
            setAlarmRule({});
            setNotifications([]);
          }}
          customerId={customerId!}
          siteId={siteId!}
          alarmData={alarm}
          setAlarmData={setAlarm}
          alarmRule={alarmRule}
          notifications={notifications ?? []}
          setRefreshTrigger={() => {}}
          onAcknowledged={(response) => {
            setLogs((prevState) => ({
              ...prevState,
              data: {
                ...prevState.data,
                results: prevState.data.results.map((item) =>
                  item.id === response.id
                    ? { ...item, acknowledgment: response.acknowledgment }
                    : item
                ),
              },
            }));
          }}
          onAcknowledgedChange={() => {}}
        />
      ) : (
        <DesktopAlarmPopUp
          open={alarmOpen}
          onClose={() => {
            setAlarmOpen(false);
            setAlarm({});
            setAlarmRule({});
            setNotifications([]);
          }}
          customerId={customerId!}
          siteId={siteId!}
          alarmData={alarm}
          setAlarmData={() => {}}
          alarmRule={alarmRule}
          notifications={notifications}
          setRefreshTrigger={() => {}}
          onAcknowledged={(response) => {
            setLogs((prevState) => ({
              ...prevState,
              data: {
                ...prevState.data,
                results: prevState.data.results.map((item) =>
                  item.id === response.id
                    ? { ...item, acknowledgment: response.acknowledgment }
                    : item
                ),
              },
            }));
          }}
          onAcknowledgedChange={() => {}}
        />
      )}

      <RestoreLogConfirmDialog
        loading={isRevertingLog}
        onConfirm={async () => handleRevertLog(logToRevert!.id)}
        onClose={() => {
          setLogToRevert(null);
        }}
        open={!!logToRevert}
        logTitle={logToRevert?.title || ''}
      />
      {logs.loaded ? (
        logs.data.results.length ? (
          <>
            <LogsList
              onAlarmSelected={(id: string) => {
                handleSelectAlarm(id, siteId!);
              }}
              onLogRevert={setLogToRevert}
              onLogSelected={(log) => {
                append('logId', log.id);
              }}
              logs={logs.data.results}
            />

            <TablePagination
              component={'div'}
              sx={{
                borderTop: 0,
                marginBottom: '90px',
                height: '42px',
                overflow: 'hidden',
                '.MuiTablePagination-selectLabel': { mt: 0, mb: 0 },
                '.MuiTablePagination-displayedRows': { mt: 0, mb: 0 },
              }}
              count={logs.data.totalCount}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={(event: unknown, nextPage) => {
                let skipTo = '';

                const isBack = nextPage < pageRef.current;
                if (nextPage && !isBack) {
                  pagesRef.current[nextPage] = logsRef.current[logsRef.current.length - 1]?.id;
                }
                if (isBack) {
                  skipTo = pagesRef.current[nextPage];
                } else {
                  skipTo = logsRef.current[logsRef.current.length - 1].id;
                }

                setPage(nextPage);
                fetchLogs(filtersRef.current, skipTo, rowsPerPageRef.current);
              }}
              onRowsPerPageChange={(event) => {
                setPage(0);
                pagesRef.current = {};
                rowsPerPageRef.current = parseInt(event.target.value) as number;
                fetchLogs(filtersRef.current, undefined, parseInt(event.target.value) as number);
                onChangeRowsPerPage(event as any);
              }}
            />
          </>
        ) : (
          <NoLogsPlaceholder />
        )
      ) : (
        <CustomWaterLoading />
      )}

      {/* Form modal */}
      {form || logId ? (
        <LogForm
          onChangeVersionHistory={(historyItem, lastItem) => {
            if (lastItem && lastItem.version === historyItem.version) return remove('version');
            append('version', historyItem.version + '');
          }}
          version={version ?? undefined}
          isCreatingLog={isCreatingLog}
          isDeletingLog={isDeletingLog}
          logId={logId}
          onSubmit={handleCreateLog}
          onDelete={handleDeleteLog}
          onClose={onClose}
          isDefault={form?.isDefault}
          id={form?.id}
        />
      ) : (
        <></>
      )}
      <FloatingAddLog
        disabled={formsState.loading || formsNotAvailable}
        renderActions={(onClose) => (
          <>
            {formsState.data.map((form) => (
              <MenuPopoverItem
                key={form.id}
                handleClick={() => {
                  onClose();

                  remove('logId');
                  append('formId', form.id);
                }}
                customContent={<>{form.name}</>}
              />
            ))}
          </>
        )}
      />
    </Container>
  );
};

export default Logs;
