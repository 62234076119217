import axios from 'axios';
const getTimeZone = async (lat: string, lng: string, timestamp: string) => {
  try {
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=${timestamp}&key=${process.env.REACT_APP_GOOGLE_PLACES_API}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const getElevation = async (lat: string, lng: string) => {
  try {
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/elevation/json?locations=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_PLACES_API}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const GoogleAPI = {
  getTimeZone,
  getElevation,
};
