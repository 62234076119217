import { FormControl, MenuItem, Select, SelectProps, useTheme } from '@mui/material';
import { FunctionComponent } from 'react';
import Label from 'src/components/minimals/form/Label';
import { AGGREGATION_SELECT_OPTIONS } from 'src/features/reports/config/config';

const AggregationSelect: FunctionComponent<
  SelectProps & { options?: { available: boolean; label: string; value: string }[] }
> = ({ options, ...props }) => {
  const theme = useTheme();
  const aggregationOptions =
    options || AGGREGATION_SELECT_OPTIONS.map((option) => ({ ...option, available: true }));

  return (
    <FormControl fullWidth data-sm="aggregation-input-field">
      <Label sx={{ backgroundColor: theme.palette.grey[200] }}>Aggregation</Label>
      <Select {...props}>
        {aggregationOptions.map((option) => (
          <MenuItem
            disabled={!option.available}
            key={option.value}
            value={`${option.value}_${option.label}`}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default AggregationSelect;
