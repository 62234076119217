import { GoogleAPI } from 'src/services/google';

type timezoneType = {
  lat: string;
  lng: string;
  time: string;
};
export const getTimezoneInfo = async ({ lat, lng, time }: timezoneType) => {
  const tz = await GoogleAPI.getTimeZone(lat, lng, time);
  return { timezoneId: tz.timeZoneId, timezoneName: tz.timeZoneName };
};
