import React, { useState, useContext, useEffect, useCallback } from 'react';
import Cookies from 'js-cookie';
import { ACCESS_TOKEN, REFRESH_TOKEN } from 'src/config';
import {
  AuthenticationProviderPropsTypes,
  AuthenticationContextTypes,
  LoginDataTypes,
  UserTypes,
  CustomerSiteIdPair,
} from '../types';
import { CUSTOMER_ID, ID_TOKEN, REMEMBER_ME, SELECTED_SITE, USER } from '../config';
import { AuthenticationService, CustomerService, UserService } from 'src/services';
import {
  getToken,
  LocalStorageService,
  createUser,
  getTokenAfterSetProfile,
  processRolesArray,
  SessionStorageService,
  decodeToken,
} from '../utils';
import { CustomerAccount } from 'src/features/customer-accounts/types/customer-account';
import { useLocation } from 'react-router-dom';
import { SiteRoles } from 'src/features/user-account-details/types';
import { GetAllResponse, SitesService } from 'src/services/sites';
import { Site } from 'src/features/sites/types/site';
import { CookiesPopup } from 'src/features/cookies/CookiesPopup';
import isEqual from 'lodash/isEqual';
import { useResponsive } from 'src/hooks';
import { useStatsigUser } from '@statsig/react-bindings';

const AuthenticationContext = React.createContext<AuthenticationContextTypes>(
  {} as AuthenticationContextTypes
);
export const useAuthentication = () => useContext(AuthenticationContext);

export const AuthenticationProvider = ({ children }: AuthenticationProviderPropsTypes) => {
  const [hasLoaded, setHasLoaded] = useState<{ user: boolean; isAnyTenantActive: boolean }>({
    user: false,
    isAnyTenantActive: false,
  });
  const [userLoggedOut, setUserLoggedOut] = useState<boolean>(false);
  const [loggedIn, setLoggedIn] = useState<{ value: boolean; loaded: boolean }>({
    value: false,
    loaded: false,
  });
  const [customerId, setCustomerId] = useState<{
    value: string | null;
    loaded: boolean;
  }>({ value: null, loaded: false });
  const [siteId, setSiteId] = useState<{
    value: string | null;
    loaded: boolean;
  }>({ value: null, loaded: false });
  const [siteIdBelongsToCustomer, setSiteIdBelongsToCustomer] = useState<{
    value: boolean;
    loaded: boolean;
  }>({ value: false, loaded: false });
  const [user, setUser] = useState<UserTypes | null | undefined>(undefined);
  const [isAnyCustomerActive, setIsAnyCustomerActive] = useState<{
    value: boolean;
    loaded: boolean;
  }>({
    value: false,
    loaded: false,
  });
  const [isCustomerAccessAllowed, setIsCustomerAccessAllowed] = useState<{
    value: boolean;
    loaded: boolean;
    isCustomerActive: boolean;
    isUserActive: boolean;
  }>({ value: false, loaded: false, isCustomerActive: false, isUserActive: false });
  const [isSiteAccessAllowed, setIsSiteAccessAllowed] = useState<{
    value: boolean;
    loaded: boolean;
  }>({ value: false, loaded: false });

  const [idToken, setIdToken] = useState('');
  const [session, setSession] = useState('');
  const [email, setUserEmail] = useState('');

  const [sites, setSites] = useState<{ value: GetAllResponse[] | null; loaded: boolean }>({
    value: [],
    loaded: false,
  });

  const { updateUserAsync } = useStatsigUser();

  const [showCookiePopup, setShowCookiePopup] = useState(false);

  const location = useLocation();
  const isMobile = useResponsive('down', 'sm');

  useEffect(() => {
    if (isMobile) {
      window.scrollTo(0, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (user) {
      updateUserAsync({
        userID: user.userId,
        email: user.email,
        custom: {
          customerId: user.customerId,
        },
      });
    }
  }, [user, updateUserAsync]);

  useEffect(() => {
    const idToken =
      LocalStorageService.getRaw(REMEMBER_ME) === 'true'
        ? LocalStorageService.getParsed(ID_TOKEN)
        : SessionStorageService.getParsed(ID_TOKEN);
    if (idToken) setIdToken(idToken);
    const tempUser =
      LocalStorageService.getRaw(REMEMBER_ME) === 'true'
        ? LocalStorageService.getParsed(USER)
        : SessionStorageService.getParsed(USER);
    if (!tempUser) return;
    setUser((prevValue) => {
      const newUser = createUser(tempUser);
      return newUser ?? prevValue;
    });
  }, []);

  useEffect(() => {
    setCustomerId({ value: null, loaded: false });
    setSiteId({ value: null, loaded: false });
  }, [location.pathname]);

  useEffect(() => {
    const { search } = location;
    const searchParams = new URLSearchParams(search);
    if (search.includes('customerId')) {
      const cId = searchParams.get('customerId');
      if (!cId) return;
      setCustomerId((prevValue) =>
        prevValue.value !== cId ? { value: cId, loaded: true } : prevValue
      );
    }
    if (search.includes('siteId')) {
      const sId = searchParams.get('siteId');
      if (!sId) return;
      setSiteId((prevValue) =>
        prevValue.value !== sId ? { value: sId, loaded: true } : prevValue
      );
    }
  }, [location]);

  const getAllCustomerIds = useCallback(() => {
    const tempUser = getUser();
    if (!tempUser) return { allIds: null, uniqueIds: null };

    if (!tempUser?.accessRoles) return { allIds: [], uniqueIds: [] };
    const roles = JSON.parse(tempUser?.accessRoles);

    const resultIds: string[] = [];
    roles.forEach((element: string) => {
      resultIds.push(element.slice(0, -1).split(':')[0]);
    });

    const uniqueIds = resultIds.filter((value, index, self) => self.indexOf(value) === index);
    return { allIds: resultIds, uniqueIds };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllAccessRoleDetailPairs = useCallback(() => {
    if (!user) return null;
    if (!user?.accessRoles) return [];

    const roles = JSON.parse(user.accessRoles);
    const pairs: CustomerSiteIdPair[] = [];
    roles.forEach((element: string) => {
      const [customerId, siteId, role, userStatus] = element.slice(0).split(':');
      pairs.push({
        customerId,
        siteId,
        role,
        userStatus,
      });
    });

    return pairs;
  }, [user]);

  const checkIsAnyCustomerActive = useCallback(async () => {
    const { uniqueIds } = { ...getAllCustomerIds() };
    if (!uniqueIds) return;

    const tempNewValue = { value: false, loaded: false };
    setIsAnyCustomerActive((prevValue) =>
      isEqual(prevValue, tempNewValue) ? prevValue : tempNewValue
    );

    if (uniqueIds && uniqueIds.length === 0) {
      const newValue = { value: false, loaded: true };
      setIsAnyCustomerActive((prevValue) => (isEqual(prevValue, newValue) ? prevValue : newValue));
      return;
    }

    CustomerService.getAllByIdsAndStatus(uniqueIds, 'active').then((response) => {
      const newValue = { value: response.length > 0, loaded: true };
      setIsAnyCustomerActive((prevValue) => (isEqual(prevValue, newValue) ? prevValue : newValue));
    });
  }, [getAllCustomerIds]);

  const getAndProcessAvailableCustomer = useCallback((id: string, pairs: CustomerSiteIdPair[]) => {
    const userStatus = pairs.find((pair) => pair.customerId === id)?.userStatus;
    CustomerService.getById(id).then((response: CustomerAccount) => {
      const newValue = {
        value: response.status === 'active' && userStatus === 'active',
        loaded: true,
        isCustomerActive: response.status === 'active',
        isUserActive: userStatus === 'active',
      };
      setIsCustomerAccessAllowed((prevValue) =>
        isEqual(prevValue, newValue) ? prevValue : newValue
      );
    });
  }, []);

  const checkCustomerAccess = useCallback(async () => {
    const { uniqueIds } = { ...getAllCustomerIds() };
    const pairs = getAllAccessRoleDetailPairs();

    if (!uniqueIds || !customerId.value || !pairs) {
      return;
    }

    const defaultValue = {
      value: false,
      loaded: false,
      isCustomerActive: false,
      isUserActive: false,
    };

    setIsCustomerAccessAllowed((prevValue) =>
      isEqual(prevValue, defaultValue) ? prevValue : defaultValue
    );

    let foundFlag = false;
    uniqueIds.forEach((id) => {
      if (!customerId.value) return;
      if (id === customerId.value) {
        foundFlag = true;
        getAndProcessAvailableCustomer(id, pairs);
      }
    });
    if (!foundFlag || (uniqueIds && uniqueIds.length === 0)) {
      const newValue = {
        value: false,
        loaded: true,
        isCustomerActive: true,
        isUserActive: true,
      };
      setIsCustomerAccessAllowed((prevValue) =>
        isEqual(prevValue, newValue) ? prevValue : newValue
      );
    }
  }, [
    getAllCustomerIds,
    getAllAccessRoleDetailPairs,
    getAndProcessAvailableCustomer,
    customerId.value,
  ]);

  const checkSiteAccess = useCallback(async () => {
    const accessRoleDetails = getAllAccessRoleDetailPairs();

    if (!accessRoleDetails || (accessRoleDetails && accessRoleDetails.length <= 0)) return;

    const defaultValue = { value: false, loaded: false };
    setIsSiteAccessAllowed((prevValue) =>
      isEqual(prevValue, defaultValue) ? prevValue : defaultValue
    );

    const foundPair = accessRoleDetails.find(
      (pair) =>
        (pair.siteId === siteId.value || pair.siteId === '*') &&
        pair.customerId === customerId.value &&
        pair.role !== SiteRoles.NO_ACCESS
    );
    if (!foundPair) {
      const newValue = {
        value: false,
        loaded: true,
      };
      setIsSiteAccessAllowed((prevValue) => (isEqual(prevValue, newValue) ? prevValue : newValue));
      return;
    }

    const newValue = {
      value: true,
      loaded: true,
    };
    setIsSiteAccessAllowed((prevValue) => (isEqual(prevValue, newValue) ? prevValue : newValue));
  }, [getAllAccessRoleDetailPairs, siteId.value, customerId.value]);

  const checkIfSiteBelongsToCustomer = useCallback(async () => {
    setSiteIdBelongsToCustomer({ value: false, loaded: false });

    if (!customerId.value) return;

    SitesService.getAll({ customerId: customerId.value }).then((response) => {
      setSites({ value: response, loaded: true });
      if (!siteId.value) return;
      const ids: string[] = response.map((site: Site) => site.id);
      ids.includes(siteId.value)
        ? setSiteIdBelongsToCustomer({ value: true, loaded: true })
        : setSiteIdBelongsToCustomer({ value: false, loaded: true });
    });
  }, [customerId.value, siteId.value]);

  const initializeLoggedInState = useCallback(async () => {
    const user = createUser(
      LocalStorageService.getRaw(REMEMBER_ME) === 'true'
        ? LocalStorageService.getParsed(USER)
        : SessionStorageService.getParsed(USER)
    );
    user
      ? setLoggedIn((prevValue) =>
          prevValue.value !== true || prevValue.loaded !== true
            ? { value: true, loaded: true }
            : prevValue
        )
      : setLoggedIn((prevValue) =>
          prevValue.value !== false || prevValue.loaded !== true
            ? { value: false, loaded: true }
            : prevValue
        );
  }, []);

  useEffect(() => {
    if (!user && userLoggedOut) return;

    initializeLoggedInState();

    checkIsAnyCustomerActive();
    checkCustomerAccess();
    checkSiteAccess();
    checkIfSiteBelongsToCustomer();
    setHasLoaded((prevState) =>
      prevState.user !== true
        ? {
            ...prevState,
            user: true,
          }
        : prevState
    );
  }, [
    user,
    userLoggedOut,
    initializeLoggedInState,
    checkIsAnyCustomerActive,
    checkCustomerAccess,
    checkSiteAccess,
    checkIfSiteBelongsToCustomer,
  ]);

  const setSessionUser = async (response: any) => {
    SessionStorageService.set(ID_TOKEN, JSON.stringify(response.data.idToken));
    setIdToken(response.data.idToken);
    const idToken = getToken(response);
    SessionStorageService.set(USER, idToken);
    const newUser = createUser(JSON.parse(idToken));
    setUser((prevValue) => (isEqual(prevValue, newUser) ? prevValue : newUser));
  };

  const setLocalUser = async (response: any) => {
    LocalStorageService.set(ID_TOKEN, JSON.stringify(response.data.idToken));
    setIdToken(response.data.idToken);
    const idToken = getToken(response);
    LocalStorageService.set(USER, idToken);
    const newUser = createUser(JSON.parse(idToken));
    setUser((prevValue) => (isEqual(prevValue, newUser) ? prevValue : newUser));
  };

  const login = async (data: LoginDataTypes, customerId: string | null = null) => {
    const response = await AuthenticationService.login(data);
    if (response.data.session) {
      setSession(response.data.session);
      setUserEmail(response.data.email);
      setUserLoggedOut(false);
      setLoggedIn({ value: true, loaded: true });
    }

    if (response.data.idToken) {
      if (customerId) {
        updateUserStatusOnSubsequentInvitation(response, customerId);
      } else {
        LocalStorageService.getRaw(REMEMBER_ME) === 'true'
          ? setLocalUser(response).then((response) => {
              checkIsAnyCustomerActive();
            })
          : setSessionUser(response).then((response) => {
              checkIsAnyCustomerActive();
            });
      }

      setUserLoggedOut(false);
      setLoggedIn({ value: true, loaded: true });
    }

    return response;
  };

  const logout = async () => {
    setUserLoggedOut(true);
    setSession('');
    setUser(null);
    checkIsAnyCustomerActive();
    if (LocalStorageService.getRaw(REMEMBER_ME) === 'true') {
      LocalStorageService.remove(USER);
      LocalStorageService.remove(ID_TOKEN);
      LocalStorageService.remove(CUSTOMER_ID);
    } else {
      SessionStorageService.remove(USER);
      SessionStorageService.remove(ID_TOKEN);
      SessionStorageService.remove(CUSTOMER_ID);
    }
    LocalStorageService.remove(SELECTED_SITE);
    LocalStorageService.remove(REMEMBER_ME);
    setLoggedIn({ value: false, loaded: true });
    setIsCustomerAccessAllowed((prevValue) => ({ ...prevValue, loaded: false }));
  };

  const changePassword = async (password: string) => {
    try {
      const response = await AuthenticationService.changePassword({
        email: email,
        session: session,
        password: password,
      });

      setSession('');
      setSessionUser(response);

      return response;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (location.pathname === '/set-profile') {
      setShowCookiePopup(true);
    } else {
      setShowCookiePopup(false);
    }
  }, [location]);

  const setSessionUserAfterSetProfile = async (response: any) => {
    const decodedIdToken = getTokenAfterSetProfile(response);
    const user = createUser(JSON.parse(decodedIdToken));

    if (!user?.accessRoles) return;

    const roles = JSON.parse(user.accessRoles);
    const customerId = roles[0].slice(0).split(':')[0];

    updateUserStatusAndToken(response.refreshToken, user.userId, customerId);
  };

  const setProfile = async (data: any) => {
    try {
      const response = await AuthenticationService.setProfile({
        email: email,
        session: session,
        password: data.password,
        firstName: data.firstName,
        lastName: data.lastName,
        mobilePhone: data.mobilePhone,
      });
      await setSessionUserAfterSetProfile(response);
      setShowCookiePopup(true);
    } catch (error) {
      console.error(error);
    } finally {
      setSession('');
    }
  };

  const updateUserStatusOnSubsequentInvitation = (response: any, customerId: string) => {
    const idToken = getToken(response);
    const user = createUser(JSON.parse(idToken));

    if (!user) return;

    updateUserStatusAndToken(response.data.refreshToken, user.userId, customerId);
  };

  const updateUserStatusAndToken = (refreshToken: string, userId: string, customerId: string) => {
    UserService.editUserStatusInMultipleCustomers({ id: userId, status: 'active' }, customerId)
      .then(() => {
        AuthenticationService.refreshAccessToken({
          refreshToken: refreshToken,
        }).then((refreshResponse) => {
          const newToken = refreshResponse.AuthenticationResult.IdToken;
          const decodedToken = decodeToken(newToken);
          setIdToken(newToken);
          LocalStorageService.getRaw(REMEMBER_ME) === 'true'
            ? LocalStorageService.set(USER, decodedToken)
            : SessionStorageService.set(USER, decodedToken);

          const tempUser = createUser(JSON.parse(decodedToken));
          setUser(tempUser);
        });
      })
      .catch((error) => console.error(error));
  };

  const refreshToken = async () => {
    const refreshToken = Cookies.get(REFRESH_TOKEN);
    const response = await AuthenticationService.refreshAccessToken({
      refreshToken: refreshToken!,
    });

    const newAccessToken = response.AuthenticationResult.AccessToken;
    Cookies.set(ACCESS_TOKEN, newAccessToken, { path: '/', domain: 'streametric.io' });
  };

  const getCurrentRole = (currentCustomerId: string | null, currentSiteId: string | null) => {
    const user =
      LocalStorageService.getRaw(REMEMBER_ME) === 'true'
        ? createUser(LocalStorageService.getParsed(USER))
        : createUser(SessionStorageService.getParsed(USER));
    if (user && user.accessRole) return user.accessRole;
    if (user && user.accessRoles) {
      return processRolesArray(user.accessRoles, currentCustomerId, currentSiteId);
    }

    return 'NO_ACCESS';
  };

  const getUser = () =>
    LocalStorageService.getRaw(REMEMBER_ME) === 'true'
      ? createUser(LocalStorageService.getParsed(USER))
      : createUser(SessionStorageService.getParsed(USER));

  // TO REFACTOR INTO A SEPARATE CONTENXT!
  const [ackData, setAckData] = useState<any>(null);

  return (
    <>
      <AuthenticationContext.Provider
        value={{
          user,
          customerId,
          siteId,
          siteIdBelongsToCustomer,
          hasLoaded,
          login,
          changePassword,
          session,
          refreshToken,
          idToken,
          logout,
          setProfile,
          getAllCustomerIds,
          getCurrentRole,
          isAnyCustomerActive,
          isCustomerAccessAllowed,
          isSiteAccessAllowed,
          checkCustomerAccess,
          loggedIn,
          getUser,
          setUser,
          ackData,
          setAckData,
          sites,
        }}
      >
        {children}
      </AuthenticationContext.Provider>
      {showCookiePopup && <CookiesPopup />}
    </>
  );
};
