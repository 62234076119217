import { useState, useEffect } from 'react';
export const useDetectOS = () => {
  const [os, setOS] = useState('');

  useEffect(() => {
    /* eslint-disable prefer-destructuring */
    const userAgent = navigator.userAgent;
    /* eslint-disable prefer-destructuring */
    const platform = (navigator as any).userAgentData?.platform || navigator.platform;

    // iOS detection
    if (/iPad|iPhone|iPod/.test(userAgent) && /iPad|iPhone|iPod/.test(platform)) {
      setOS('iOS');
    }
    // Android detection
    else if (/android/i.test(userAgent)) {
      setOS('Android');
    }
    // Default to unknown or other OS
    else {
      setOS('Other');
    }
  }, []);

  return os;
};
