import { useEffect, useState } from 'react';
import { useAuthentication } from 'src/features/authentication/context';
import { TagType } from 'src/features/sites/types/types';
import { DropResult } from 'react-beautiful-dnd';
import { reorder } from 'src/utilities';

type Props = {
  isLiveDataWidget?: boolean;
  title: string;
  initialRows: { value: RowType[]; loaded: boolean };
  selectedSite: string | null;
  onReorder?: (newRows: RowType[]) => void;
  onRemoveRow?: (newRows: RowType[]) => void;
  onTestSetRow?: (rowId: string, tagId: string, gatewayId: string, parentType: string) => void;
};

type RowType = {
  id: string;
  position: number;
  tagId: string;
  gatewayId?: string;
  parentType?: 'gateway' | 'dataSource';
  tag?: TagType | null;
};
export const useDraggableList = ({
  isLiveDataWidget,
  initialRows,
  selectedSite,
  onReorder,
  onRemoveRow,
  onTestSetRow,
}: Props) => {
  const {
    customerId: { value: customerId },
  } = useAuthentication();

  const [mobileListWidth, setMobileListWidth] = useState<string>('100%');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [rows, setRows] = useState<RowType[]>(
    Array.from({ length: 1 }, (v, k) => k).map((k) => ({
      id: `${k + 1}`,
      position: k + 1,
      tagId: '',
      gatewayId: '',
      parentType: 'gateway',
      tag: null,
    }))
  );

  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  useEffect(() => {
    if (!selectedSite || !customerId) return;
    setRows(initialRows.value);
  }, [selectedSite, customerId, initialRows]);

  useEffect(() => {
    if (rows.length <= 0) return;

    setSelectedTags(rows.map((row) => row.tagId));
    !rowsHaveValue() ? setMobileListWidth('100%') : setMobileListWidth('fit-content');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  const rowsHaveValue = (): boolean => rows.some((item) => item && item.tagId !== '');

  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) return;

    const newItems = reorder(rows, source.index, destination.index);

    setRows(newItems);

    if (onReorder) onReorder(newItems);
  };

  const addRow = () => {
    if (isLiveDataWidget && rows.length >= 6) return;
    if (!isLiveDataWidget && rows.length === 12) return;
    setRows((prevValue) => {
      const newRows: any[] = [];
      prevValue.forEach((val) => {
        newRows.push(val);
      });

      const maxId = newRows.reduce((max, obj) => {
        const id = Number(obj.id);
        return id > max ? id : max;
      }, 0);
      newRows.push({
        id: `${maxId + 1}`,
        position: maxId + 1,
        gatewayId: '',
        tagId: '',
        parentType: 'gateway',
        tag: null,
      });
      return newRows.map((item, index) => ({
        ...item,
        position: index + 1,
      }));
    });
  };

  const removeRow = (id: string) => {
    setRows((prevValue) => {
      const newRows: any[] = [];
      prevValue.forEach((val) => {
        if (val.id !== id) newRows.push(val);
      });
      return newRows.map((item, index) => ({
        ...item,
        position: index + 1,
      }));
    });
    if (onRemoveRow) onRemoveRow(rows.filter((row) => row.id !== id));
  };
  const handleSetRow = (
    rowId: string,
    tagId: string,
    gatewayId: string,
    parentType: string,
    tag: TagType | null
  ) => {
    setRows((prevValue) => {
      const newValues: any[] = [];
      prevValue.forEach((val) => {
        if (val.id === rowId) {
          newValues.push({
            id: val.id,
            position: val.position,
            gatewayId: gatewayId,
            tagId: tagId,
            parentType: (parentType as 'gateway') || 'dataSource',
            tag: tag,
          });
        } else {
          newValues.push(val);
        }
      });

      return newValues;
    });

    if (onTestSetRow) onTestSetRow(rowId, tagId, gatewayId, parentType);
  };

  return {
    isSubmitting,
    rows,
    selectedTags,
    onDragEnd,
    addRow,
    removeRow,
    handleSetRow,
    mobileListWidth,
    setMobileListWidth,
    setIsSubmitting,
  };
};
