import { Autocomplete, Box, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import usePlacesAutocomplete, { getGeocode, getZipCode, getLatLng } from 'use-places-autocomplete';
import { AutocompleteLocationProps, GooglePrediction } from './types';
import { getTimezoneInfo } from 'src/hooks';

export const AutocompleteLocation = ({ onChange, defaultValue }: AutocompleteLocationProps) => {
  const [selectedValue, setSelectedValue] = useState<GooglePrediction | null>(null);

  const {
    setValue,
    suggestions: { data },
  } = usePlacesAutocomplete({
    requestOptions: { types: ['geocode'] },
    debounce: 300,
    cache: 3600 * 12,
  });
  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
      if (data) {
        setSelectedValue(data?.[0] ?? null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, defaultValue]);

  const handleChange = async (event: any, newValue: GooglePrediction | null) => {
    setSelectedValue(newValue);

    if (!newValue) {
      onChange?.(null);
      return;
    }

    const { terms, description } = newValue;
    let address = '',
      city = '',
      state = '',
      country = '',
      zipcode = null,
      coordinates = null;
    let timeZoneInfo = { timeZoneId: '', timeZoneName: '' };

    if (newValue.types?.includes('locality')) {
      city = terms?.[0]?.value ?? '';
      state = terms?.[1]?.value ?? '';
      country = terms?.[2]?.value ?? '';
    } else {
      address = `${terms?.[0]?.value ?? ''} ${terms?.[1]?.value ?? ''}`.trim();
      city = terms?.[2]?.value ?? '';
      state = terms?.[3]?.value ?? '';
      country = terms?.[4]?.value ?? '';
    }

    try {
      const results = await getGeocode({ address: description });
      zipcode = getZipCode(results[0], false);
      coordinates = getLatLng(results[0]);
      const currTimeSeconds = Math.round(Date.now() / 1000);
      const { timezoneId: timeZoneId, timezoneName: timeZoneName } = await getTimezoneInfo({
        lat: coordinates.lat.toString(),
        lng: coordinates.lng.toString(),
        time: currTimeSeconds.toString(),
      });
      timeZoneInfo = { timeZoneId, timeZoneName };
    } catch (error) {
      console.error('Error fetching address details:', error);
    }

    onChange?.({
      description,
      address,
      city,
      state,
      country,
      zipcode,
      coordinates,
      timeZoneInfo,
    });
  };

  return (
    <Autocomplete
      id="location-autocomplete"
      forcePopupIcon={false}
      options={data}
      getOptionLabel={(option: GooglePrediction | string) => {
        if (typeof option === 'string') return option;
        return option?.structured_formatting?.main_text || option?.description || '';
      }}
      renderOption={(props, option: GooglePrediction) => (
        <Box component="li" {...props}>
          <Box>{option.description}</Box>
        </Box>
      )}
      isOptionEqualToValue={(option: GooglePrediction, value: GooglePrediction) =>
        option?.place_id === value?.place_id
      }
      renderInput={(params) => <TextField {...params} label="Address" />}
      onInputChange={(event, newValue) => setValue(newValue)}
      onChange={handleChange}
      value={selectedValue}
    />
  );
};

export default AutocompleteLocation;
