import {
  Button,
  Checkbox,
  DialogActions,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { SearchFillIcon } from 'src/assets';
import { useResponsive } from 'src/hooks';
import { AddDialogStyled, AddDialogContentWithCustomScroll } from './style';
import { useEffect, useState } from 'react';
import { CustomWaterLoading } from 'src/components';

interface ChipData {
  key: string;
  label: string;
}

type Props = {
  isOpen: boolean;
  dialogTitle: string;
  selectTitle: string;
  onClose: () => void;
  chipData: { value: ChipData[]; loaded: boolean };
  isChecked: (key: string) => boolean;
  handleChange: (item: string, checked: boolean) => void;
  onSubmit: VoidFunction;
  setTempData: (data: any[]) => void;
  entity: string;
};

export const AddDialog = ({
  isOpen,
  dialogTitle,
  selectTitle,
  onClose,
  chipData,
  isChecked,
  handleChange,
  onSubmit,
  setTempData,
  entity,
}: Props) => {
  const theme = useTheme();
  const isMobile = useResponsive('down', 'md');

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [filteredData, setFilteredData] = useState<{ value: ChipData[]; loaded: boolean }>(
    chipData
  );
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [numberOfSelectedItems, setNumberOfSelectedItems] = useState(0);

  useEffect(() => {
    if (!chipData.loaded) return;
    const lowerCaseQuery = searchQuery.toLowerCase();
    const filtered = chipData.value.filter((item) =>
      item.label.toLowerCase().includes(lowerCaseQuery)
    );
    setFilteredData((prevValue) => ({ value: filtered, loaded: true }));

    let numberOfPreviouslySelectedItems = 0;

    filtered.forEach((item) => {
      if (isChecked(item.key.toString())) {
        numberOfPreviouslySelectedItems++;
      }
    });

    if (numberOfPreviouslySelectedItems === filtered.length) {
      setIsSelectAll(true);
    } else {
      setIsSelectAll(false);
    }
    setNumberOfSelectedItems(numberOfPreviouslySelectedItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, chipData]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleOnClose = () => {
    onClose();
    setTimeout(() => {
      setFilteredData(chipData);
    }, 300);
  };

  const handleOnSubmit = () => {
    onSubmit();
    setTimeout(() => {
      setFilteredData(chipData);
    }, 300);
  };

  const handleCurrentChange = (item: string, checked: boolean) => {
    checked
      ? setNumberOfSelectedItems(numberOfSelectedItems + 1)
      : setNumberOfSelectedItems(numberOfSelectedItems - 1);
    handleChange(item, checked);

    if (checked && numberOfSelectedItems + 1 === filteredData.value.length) {
      setIsSelectAll(true);
    } else {
      setIsSelectAll(false);
    }
  };

  const handleSelectAll = (item: string, checked: boolean) => {
    setIsSelectAll(checked);

    let newEntities: any[] = [];
    if (checked) {
      newEntities = filteredData.value.map((data) => data.key);
      setNumberOfSelectedItems(filteredData.value.length);
    } else {
      newEntities = [];
      setNumberOfSelectedItems(0);
    }

    setTempData(newEntities);
  };

  return (
    <AddDialogStyled open={isOpen} onClose={handleOnClose} maxWidth={'lg'}>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <Divider />
      <AddDialogContentWithCustomScroll sx={{ p: 3 }}>
        <Stack>
          <Typography variant="body1">{selectTitle}</Typography>
          <TextField
            sx={{ mt: '8px', mb: '8px' }}
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchFillIcon fill={theme.palette.text.disabled} />
                </InputAdornment>
              ),
            }}
            inputProps={{ 'data-sm': `${entity.toLocaleLowerCase()}-input` }}
            onChange={handleSearchChange}
          />
          <Stack maxHeight="320px" sx={{ alignItems: 'flex-start' }}>
            <FormGroup key={'select-all'} sx={{ ml: isMobile ? 0 : '8px' }}>
              <FormControlLabel
                sx={{
                  color: 'text.secondary',
                  flexDirection: 'row',
                  marginLeft: '-16px!important',
                }}
                labelPlacement={'start'}
                label={
                  <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Typography variant="body2" sx={{ ml: '4px' }}>
                      All {entity}
                    </Typography>
                  </Stack>
                }
                control={
                  <Checkbox
                    inputProps={{
                      // @ts-ignore
                      'data-sm': `select-all-checkbox`,
                    }}
                    indeterminate={
                      filteredData.value.length > numberOfSelectedItems && numberOfSelectedItems > 0
                    }
                    value={isSelectAll}
                    checked={isSelectAll}
                    onChange={(e, checked) => handleSelectAll(e.target.value, checked)}
                  />
                }
              />
            </FormGroup>
            <Divider sx={{ width: '100%', mt: '4px', mb: '4px' }} />
            {filteredData.loaded ? (
              filteredData.value.map((data) => (
                <FormGroup key={data.key} sx={{ ml: isMobile ? 0 : '8px' }}>
                  <FormControlLabel
                    sx={{
                      color: 'text.secondary',
                      flexDirection: 'row',
                      marginLeft: '-16px!important',
                    }}
                    labelPlacement={'start'}
                    label={
                      <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ ml: '4px' }}>
                          {data.label}
                        </Typography>
                      </Stack>
                    }
                    control={
                      <Checkbox
                        inputProps={{
                          // @ts-ignore
                          'data-sm': `${data.key}-checkbox`,
                        }}
                        value={data.key.toString()}
                        checked={isChecked(data.key.toString())}
                        onChange={(e, checked) => handleCurrentChange(e.target.value, checked)}
                      />
                    }
                  />
                </FormGroup>
              ))
            ) : (
              <Stack sx={{ width: '100%', alignItems: 'center', mt: '8px' }}>
                <CustomWaterLoading />
              </Stack>
            )}
          </Stack>
        </Stack>
      </AddDialogContentWithCustomScroll>
      <Divider />
      <DialogActions>
        <Button variant="outlined" data-sm="discard-modal-button" onClick={handleOnClose}>
          Cancel
        </Button>
        <Button variant="contained" data-sm="apply-changes-modal-button" onClick={handleOnSubmit}>
          Add
        </Button>
      </DialogActions>
    </AddDialogStyled>
  );
};

export default AddDialog;
