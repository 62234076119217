/* eslint-disable no-restricted-globals */
export const worker = () => {
  self.addEventListener('message', async (e) => {
    if (!e || !e.data) {
      self.postMessage({ status: 'error', error: 'Invalid message received in worker.' });
      return;
    }

    const { gatewayId, startDate, endDate, measurements, aggregationType, statistics } = e.data;

    if (!gatewayId) {
      self.postMessage({ status: 'error', error: 'Missing gatewayId in message.' });
      return;
    }

    const queryParams = new URLSearchParams();
    if (startDate) queryParams.append('start_date', startDate);
    if (endDate) queryParams.append('end_date', endDate);
    if (measurements) queryParams.append('measurements', measurements);
    if (aggregationType) queryParams.append('aggregation_type', aggregationType);
    if (statistics) queryParams.append('statistics', statistics);

    const url = `${
      process.env.REACT_APP_STREAMETRIC_API
    }/v2/data/${gatewayId}/trends?${queryParams.toString()}`;

    try {
      const response = await fetch(url, {
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      self.postMessage({ status: 'success', response: responseData });
    } catch (error) {
      console.error('Error fetching data:', error);
      self.postMessage({ status: 'error', error: error.message });
    }
  });
};
