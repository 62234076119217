import { Alert, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { MapContainer } from 'react-leaflet';

type StylesTypes = {
  isLoaded?: boolean;
  isMobile?: boolean;
};

export const StyledMapContainer = styled(MapContainer)(() => ({
  height: '100%',
  width: '100%',
  '& .leaflet-control': {
    zIndex: '0',
  },
  '& .leaflet-pane': {
    zIndex: '0',
  },
  '& .leaflet-top': {
    zIndex: '0',
  },
  '& .leaflet-bottom': {
    zIndex: '0',
  },
  '& .leaflet-tile-pane': {
    opacity: 0.8,
  },
  '& .leaflet-popup-close-button': {
    paddingTop: '16px',
  },
}));

export const StyledMapBlurBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isLoaded',
})(({ isLoaded }: StylesTypes) => ({
  height: '100%',
  width: '100%',
  '& .leaflet-pane': {
    zIndex: '0',
    filter: !isLoaded ? 'blur(8px)' : 'none',
  },
  position: 'relative',
}));

export const StyledMapAlert = styled(Alert)(() => ({
  position: 'relative',
  '@media (max-width:600px)': {
    flexDirection: 'column',
  },
  flexDirection: 'row',
  '& .MuiAlert-icon': { display: 'none' },
  '& .MuiCardContent-root': { padding: 0 },
  '& .MuiAlert-message': { padding: 0 },
}));

export const loadingBox = {
  height: '100%',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const alertMessage = {
  minHeight: '40px',
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  alignItems: 'center',
  gridGap: '12px',
  fill: 'warning.main',
};

export const alertIcon = {
  height: '18px',
};

export const alertList = {
  maxHeight: '300px',
  overflowY: 'auto',
  listStyleType: 'disc',
};

export const listItemText = {
  display: 'list-item',
};
