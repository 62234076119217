import { styled, Theme } from '@mui/material/styles';
import { Box } from '@mui/material';

type StylesTypes = {
  theme: Theme;
};

const controlsContainer = {
  position: 'absolute',
  bottom: '100px',
  right: '16px',
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  '@media (min-width:900px)': {
    gap: '16px',
    bottom: '68px',
    right: '40px',
  },
};

const ControlsIcon = styled(Box)(({ theme }: StylesTypes) => ({
  background: theme.palette.common.white,
  boxShadow: '0px 3px 8px 3px rgba(0, 0, 0, 0.13)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '8px',
  cursor: 'pointer',
  '&:hover': {
    transform: 'scale(1.15);',
  },
  padding: '8px',
}));

export { controlsContainer, ControlsIcon };
