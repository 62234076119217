import React from 'react';
import { Box, Tooltip } from '@mui/material';
import { IconsTypesProps } from 'src/assets/icons/types';
import { useLocation, useNavigate } from 'react-router';
import useQueryParamsActions from 'src/hooks/useQueryParamsActions';
import { NavItem } from '../navItem';
import { useBlockNavigation } from 'src/routes/elements/ReportFlow/BuildReportsElement/blockNavigationContext';
import { confirmNavigationProceed } from 'src/routes/elements/ReportFlow/BuildReportsElement/confirmNavigationProceed';

type Props = {
  title: string;
  url: string;
  icon: React.FunctionComponent<IconsTypesProps>;
  greyColor: string;
  activeItem: string;
  onSetActiveItemHandler: (newActiveItem: string) => void;
};

export const ExtendedNavItem = ({
  icon,
  title,
  greyColor,
  url,
  activeItem,
  onSetActiveItemHandler,
}: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const blockNavigation = useBlockNavigation();

  const onClickHandler = () => {
    let shouldBlockNavigation = false;
    if (blockNavigation && blockNavigation[0]) {
      shouldBlockNavigation = !confirmNavigationProceed();
    }
    if (shouldBlockNavigation) return false;
    const currentQueryParams = location.search;
    navigate(`${url}${currentQueryParams}`);
    onSetActiveItemHandler(title);
  };

  const { get } = useQueryParamsActions();
  const templateId = get('templateId');
  const isHistory = title === 'History';
  const isSchedule = title === 'Schedule';

  return (
    <>
      {(isHistory && !templateId) || (isSchedule && !templateId) ? (
        <Tooltip title={isSchedule ? 'Save the template to enable scheduling' : 'No saved reports'}>
          <Box>
            <NavItem
              testId={`${title.toLowerCase()}-extended-item`}
              title={title}
              icon={icon}
              greyColor={greyColor}
              activeItem={activeItem}
            />
          </Box>
        </Tooltip>
      ) : (
        <NavItem
          title={title}
          icon={icon}
          greyColor={greyColor}
          activeItem={activeItem}
          onClickHandler={onClickHandler}
          testId={`${title.toLowerCase()}-extended-item`}
        />
      )}
    </>
  );
};
