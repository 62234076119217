import { Responsive, WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { Box } from '@mui/material';
import { WidgetComponent } from '../widget-component';
import { WidgetType } from '../../types/types';
import { useResponsive } from 'src/hooks';
import { TagResponse } from 'src/features/alarm-rules/types';
import { Controller } from 'src/features/controllers/types';

type WidgetsListProps = {
  widgets: WidgetType[];
  setLayoutHandler: (layout: any) => void;
  layout: any;
  isEditMode: boolean;
  isDesktop: boolean;
  toggleAddOrRemoveHandler: () => void;
  gatewayInputs: TagResponse[];
  isAddedController?: boolean;
  controllers?: Controller[];
  getControllers: () => void;
  areGatewatInputsReady: boolean;
};

const GridLayout = WidthProvider(Responsive);

export const WidgetList = ({
  widgets,
  setLayoutHandler,
  layout,
  isEditMode,
  isDesktop,
  toggleAddOrRemoveHandler,
  gatewayInputs,
  isAddedController,
  controllers,
  getControllers,
  areGatewatInputsReady,
}: WidgetsListProps) => {
  const isTablet = useResponsive('up', 'md');
  const listOfWidgets = widgets.map((widget: WidgetType) => (
    <Box key={widget.id}>
      <WidgetComponent
        isEditMode={isEditMode}
        widget={widget}
        key={widget.id}
        toggleAddOrRemoveHandler={toggleAddOrRemoveHandler}
        gatewayInputs={gatewayInputs}
        isAddedController={isAddedController}
        controllers={controllers}
        getControllers={getControllers}
        areGatewatInputsReady={areGatewatInputsReady}
      />
    </Box>
  ));

  return (
    <>
      {isDesktop ? (
        <GridLayout
          onLayoutChange={(layout) => {
            setLayoutHandler(layout);
          }}
          className="layout"
          breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
          cols={{ lg: 8, md: 6, sm: 4, xs: 2, xxs: 2 }}
          layouts={{ lg: layout }}
          isResizable={false}
          isDraggable={isEditMode ? true : false}
        >
          {listOfWidgets}
        </GridLayout>
      ) : (
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: isTablet ? 'repeat(2,1fr)' : 'repeat(1,1fr)',
            gridGap: '20px',
          }}
        >
          {listOfWidgets}
        </Box>
      )}
    </>
  );
};
