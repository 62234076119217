export const DarkLogoIcon = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="8" fill="#21374E" />
    <path
      d="M19.7847 5.15723H20.0182C20.9033 5.18938 21.3721 5.81726 21.8274 6.44852C22.0357 6.74032 22.2186 7.04947 22.374 7.37257C23.4606 9.57267 24.6825 11.6966 25.8333 13.8595C26.6795 15.4487 27.5426 17.0293 28.4057 18.61C29.3856 20.404 30.3418 22.2081 31.3488 23.9884C31.3837 24.0469 31.4126 24.1087 31.4351 24.1729C31.5096 24.3946 31.4486 24.4928 31.2168 24.5436C30.7958 24.6129 30.3681 24.6334 29.9424 24.6045C29.6488 24.5982 29.3581 24.6645 29.0962 24.7974C27.2346 25.7181 25.3391 26.5913 23.4605 27.4934C22.0017 28.1963 20.54 28.8896 19.0756 29.5733C16.9008 30.5888 14.7312 31.6245 12.5497 32.6196C11.6172 33.0478 10.6881 33.5065 9.73188 33.8619C8.91253 34.2007 8.01253 34.2947 7.14083 34.1326C6.81374 34.0654 6.50746 33.921 6.24752 33.7114C5.98757 33.5018 5.78149 33.2331 5.64645 32.9277C5.55017 32.606 5.49055 32.2744 5.46875 31.9393V30.9983C5.50941 30.8904 5.52108 30.7737 5.5026 30.6599C5.54451 30.1374 5.68764 29.6282 5.924 29.1604C6.67373 27.5459 7.44715 25.9364 8.2815 24.3659C8.67414 23.6314 9.03969 22.8816 9.42217 22.1421C10.6136 19.8551 11.8152 17.5765 13.027 15.3065C13.7208 13.9966 14.4215 12.6912 15.1289 11.3903C15.9226 9.93654 16.6808 8.46585 17.4847 7.01886C17.6895 6.66496 17.9257 6.3302 18.1904 6.01865C18.3713 5.76145 18.6095 5.54976 18.8862 5.40027C19.1628 5.25079 19.4704 5.16759 19.7847 5.15723Z"
      fill="#05B6B2"
    />
    <path
      d="M34.5653 31.2573C34.5513 31.8217 34.4162 32.3766 34.1692 32.8843C33.9222 33.3921 33.569 33.8409 33.1335 34.2003C32.593 34.6391 31.9118 34.867 31.2161 34.8417C31.0905 34.8346 30.9672 34.8048 30.8522 34.7537L21.957 31.3266C21.8808 31.2962 21.7539 31.2979 21.7539 31.1946C21.7539 31.0914 21.8859 31.0694 21.9722 31.0355C23.9472 30.2858 25.924 29.5429 27.8956 28.7864C28.9618 28.3785 30.0398 27.9926 31.0705 27.4951C31.2263 27.438 31.3959 27.4303 31.5562 27.4731C33.4669 27.7591 34.5738 29.0656 34.5653 31.2573Z"
      fill="#05B6B2"
    />
  </svg>
);
