export const MapHomeIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_14447_74577" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
      <rect width="20" height="20" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_14447_74577)">
      <path
        d="M4.99992 15.8327H7.49992V11.666C7.49992 11.4299 7.57978 11.232 7.7395 11.0723C7.89922 10.9125 8.09714 10.8327 8.33325 10.8327H11.6666C11.9027 10.8327 12.1006 10.9125 12.2603 11.0723C12.4201 11.232 12.4999 11.4299 12.4999 11.666V15.8327H14.9999V8.33268L9.99992 4.58268L4.99992 8.33268V15.8327ZM3.33325 15.8327V8.33268C3.33325 8.06879 3.39228 7.81879 3.51034 7.58268C3.62839 7.34657 3.79159 7.15213 3.99992 6.99935L8.99992 3.24935C9.29159 3.02713 9.62492 2.91602 9.99992 2.91602C10.3749 2.91602 10.7083 3.02713 10.9999 3.24935L15.9999 6.99935C16.2083 7.15213 16.3714 7.34657 16.4895 7.58268C16.6076 7.81879 16.6666 8.06879 16.6666 8.33268V15.8327C16.6666 16.291 16.5034 16.6834 16.177 17.0098C15.8506 17.3362 15.4583 17.4993 14.9999 17.4993H11.6666C11.4305 17.4993 11.2326 17.4195 11.0728 17.2598C10.9131 17.1 10.8333 16.9021 10.8333 16.666V12.4993H9.16658V16.666C9.16658 16.9021 9.08672 17.1 8.927 17.2598C8.76728 17.4195 8.56936 17.4993 8.33325 17.4993H4.99992C4.54159 17.4993 4.14922 17.3362 3.82284 17.0098C3.49645 16.6834 3.33325 16.291 3.33325 15.8327Z"
        fill="#637381"
      />
    </g>
  </svg>
);
