import { useEffect, useRef } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import { Box } from '@mui/material';
import { MapZoomOutIcon } from 'src/assets/icons/MapZoomOutIcon';
import { MapZoomInIcon } from 'src/assets/icons/MapZoomInIcon';
import { MapHomeIcon } from 'src/assets/icons/MapHomeIcon';
import { ControlsIcon, controlsContainer } from './style';

export const MapNavigationControls = ({ bounds }: { bounds: L.LatLngBoundsLiteral }) => {
  const boxRef = useRef<HTMLDivElement>(null);
  const map = useMap();

  const handleZoomIn = () => {
    map.zoomIn();
  };

  const handleZoomOut = () => {
    map.zoomOut();
  };

  const handleHomeView = () => {
    map.fitBounds(bounds, { duration: 2, maxZoom: map.getZoom() });
  };

  // disable zoom when double-click on controls
  useEffect(() => {
    if (boxRef?.current) {
      L.DomEvent.disableClickPropagation(boxRef.current);
      L.DomEvent.disableScrollPropagation(boxRef.current);
    }
  }, []);

  return (
    <Box ref={boxRef} sx={controlsContainer}>
      <ControlsIcon onClick={handleHomeView}>
        <MapHomeIcon />
      </ControlsIcon>
      <ControlsIcon data-testid="map-zoom-in" onClick={handleZoomIn}>
        <MapZoomInIcon />
      </ControlsIcon>
      <ControlsIcon data-testid="map-zoom-out" onClick={handleZoomOut}>
        <MapZoomOutIcon />
      </ControlsIcon>
    </Box>
  );
};

export default MapNavigationControls;
