import L from 'leaflet';
import icon from './pinIcon.svg';

const PinIcon = new L.Icon({
  iconUrl: icon,
  iconRetinaUrl: icon,
  iconSize: new L.Point(30, 35),
  className: 'custom-pin-icon',
});

export { PinIcon };
