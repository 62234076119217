import { TagsTypes, TagType } from '../../types/types';
import { TagItem } from '../tag-item';
import { StyledTagList } from './style';

type Props = {
  tags: TagsTypes;
  testId?: string;
  isMobile: boolean;
  liveDataComplete?: any;
};

export const TagsList = ({ tags, testId, isMobile, liveDataComplete }: Props) => (
  <StyledTagList isMobile={isMobile} data-testid={testId}>
    {tags?.tags
      ?.sort((a, b) => (a.position ?? 0) - (b.position ?? 0))
      .map((item: TagType) => (
        <TagItem
          testId={`tag-item-${item.tagId}`}
          key={item.tagId}
          tag={item}
          isMobile={isMobile}
          liveDataComplete={liveDataComplete}
        />
      ))}
  </StyledTagList>
);
